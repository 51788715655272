import(/* webpackMode: "eager" */ "/app/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/(app)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/sonner/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/src/app/(app)/globals.css");
import(/* webpackMode: "eager" */ "/app/src/app/(app)/PostHogPageView.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(app)/providers.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui/tooltip.tsx")